import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import {
  BirthdayDay,
  BirthdayMonth,
  BirthdayYear,
  FamiliesMetadata,
  LoginPayload,
  OnboardingStatus,
  RegisterEmailRequestPayload,
  ResetPasswordPayload,
  SignupPayload,
} from '../../api/types'
import { RootState } from '../store'

interface ProfileState {
  id: string | null
  email: string
  name: string
  onboardingStatus: OnboardingStatus | ''
  onboardingVersion: string
  ownerOfFaId?: number | null
  userAdminFamilies: FamiliesMetadata
  emailConfirmed: boolean
  birthdayDay: BirthdayDay | null
  birthdayMonth: BirthdayMonth | null
  birthdayYear: BirthdayYear | null
  userTimezone: string | null
  settings: {
    giftCardPricingDisplayVersion: 'A' | 'B'
    timezoneCountry: string
  }
}

// Define a type for the slice state
interface AuthState {
  profile: ProfileState
  isAuthChecked: boolean
  isAuthenticated: boolean
  isResettingPassword: boolean
  isProfileLoaded: boolean
  isCreatingPasswordResetLink: boolean
  passwordResetLinkCreationError: string
  passwordResetError: string
  loginError: string
  isLoggingIn: boolean
  signupError: string
  isSigningUp: boolean
  isRegisteringEmail: boolean
  isUpdatingProfile: boolean
  profileUpdateError: string
  isPasswordLinkCreationSuccesful: boolean
}

// Define the initial state using that type
const initialState: AuthState = {
  isAuthChecked: false,
  isAuthenticated: false,
  isResettingPassword: false,
  isProfileLoaded: false,
  isCreatingPasswordResetLink: false,
  passwordResetLinkCreationError: '',
  passwordResetError: '',
  loginError: '',
  isLoggingIn: false,
  signupError: '',
  isSigningUp: false,
  isUpdatingProfile: false,
  profileUpdateError: '',
  isRegisteringEmail: false,
  isPasswordLinkCreationSuccesful: false,
  profile: {
    id: null,
    email: '',
    name: '',
    onboardingVersion: '',
    onboardingStatus: OnboardingStatus.IN_PROGRESS,
    userAdminFamilies: [],
    emailConfirmed: false,
    birthdayDay: null,
    birthdayMonth: null,
    birthdayYear: null,
    userTimezone: null,
    settings: {
      giftCardPricingDisplayVersion: 'A',
      timezoneCountry: '',
    },
  },
}

export const getProfile = createAsyncThunk(
  'auth/getProfile',
  async function () {
    const response = await api.getProfile()
    return response
  }
)

export const registerEmail = createAsyncThunk(
  'auth/registerEmail',
  async function (payload: RegisterEmailRequestPayload) {
    const response = await api.registerEmail(payload)
    return response
  }
)

export const login = createAsyncThunk(
  'auth/login',
  async function (payload: LoginPayload) {
    const response = await api.login(payload)
    return response
  }
)

export const signup = createAsyncThunk(
  'auth/signup',
  async function (payload: SignupPayload) {
    const response = await api.signup(payload)
    return response
  }
)

export const createPasswordResetLink = createAsyncThunk(
  'auth/createPasswordResetLink',
  async function ({ email }: { email: string }) {
    const response = await api.createPasswordResetLink({ email })
    return response
  }
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async function (payload: ResetPasswordPayload) {
    const response = await api.resetPassword(payload)
    return response
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      if (!action.payload) {
        return
      }
      state.profile = action.payload
      state.isProfileLoaded = true
      state.isAuthChecked = true
      state.isAuthenticated = true
    })
    builder.addCase(registerEmail.pending, (state) => {
      state.isRegisteringEmail = true
      state.signupError = ''
    })

    builder.addCase(registerEmail.fulfilled, (state, action) => {
      state.isRegisteringEmail = false
      if (action.payload) {
        state.profile.email = action.payload.email
      }
    })

    builder.addCase(registerEmail.rejected, (state, action) => {
      state.isRegisteringEmail = false
      state.signupError = action.error.message || ''
    })

    builder.addCase(login.pending, (state) => {
      state.isLoggingIn = true
      state.loginError = ''
    })

    builder.addCase(login.fulfilled, (state) => {
      state.isLoggingIn = false
      state.isAuthChecked = true
      state.isAuthenticated = true
    })

    builder.addCase(login.rejected, (state, action) => {
      state.isLoggingIn = false
      state.isAuthChecked = true
      state.isAuthenticated = false
      state.loginError = action.error.message || ''
    })

    builder.addCase(signup.pending, (state) => {
      state.isSigningUp = true
      state.signupError = ''
    })
    builder.addCase(signup.fulfilled, (state) => {
      state.isSigningUp = false
    })

    builder.addCase(signup.rejected, (state, action) => {
      state.isSigningUp = false
      state.isAuthChecked = true
      state.isAuthenticated = false
      state.signupError = action.error.message || ''
    })

    builder.addCase(resetPassword.pending, (state) => {
      state.isResettingPassword = true
      state.passwordResetError = ''
    })

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.isResettingPassword = false
    })

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isResettingPassword = false
      state.passwordResetError = action.error.message || ''
    })

    builder.addCase(createPasswordResetLink.pending, (state) => {
      state.isPasswordLinkCreationSuccesful = false
      state.isCreatingPasswordResetLink = true
      state.passwordResetLinkCreationError = ''
    })

    builder.addCase(createPasswordResetLink.fulfilled, (state) => {
      state.isCreatingPasswordResetLink = false
      state.isPasswordLinkCreationSuccesful = true
    })

    builder.addCase(createPasswordResetLink.rejected, (state, action) => {
      state.isCreatingPasswordResetLink = false
      state.passwordResetLinkCreationError = action.error.message || ''
    })
  },
})

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth
export const selectProfile = (state: RootState) => state.auth.profile

export const selectAuthError = createSelector([selectAuth], (authState) => {
  return (
    authState.loginError ||
    authState.signupError ||
    authState.passwordResetError ||
    authState.passwordResetLinkCreationError ||
    authState.profileUpdateError
  )
})

export const selectIsEmailRegistered = createSelector(
  [selectAuth],
  (authState) => {
    return authState.isAuthenticated && !!authState.profile.email
  }
)

export default authSlice.reducer
